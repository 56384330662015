<template>
  <div id="rebate_payment_history" class="rebate_payment_history">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.payHistory') }}</h2>

          <p></p>
        </div>

        <div class="calendar_content">
          <Rebate ref="rebate" :userID="userID" @accountIdAndCurrency="getAccountIdAndCurrency"></Rebate>
        </div>

        <div class="content_box">
          <h2>{{ $t('menu.payHistory') }}</h2>
          <div class="datepicker">
            <DateRangePicker v-bind:start-date.sync="startDate" v-bind:end-date.sync="endDate"></DateRangePicker>

            <div class="form_button">
              <el-button class="purple_button" @click="setStartAndEndDate" data-testid="UPDATE">
                {{ $t('home.UPDATE') }}
              </el-button>
            </div>
          </div>
          <div class="select_tab">
            <ul>
              <li
                :class="{ active: active === 'rebateHistory' }"
                class="white_button li_button"
                @click="active = 'rebateHistory'"
                data-testid="rebateHistory"
              >
                {{ $t('rebatePaymentHistory.rebateHistory') }}
              </li>

              <li
                :class="{ active: active === 'withdrawHistory' }"
                class="white_button li_button"
                @click="active = 'withdrawHistory'"
                data-testid="withdrawHistory"
              >
                {{ $t('rebatePaymentHistory.withdrawHistory') }}
              </li>

              <li
                :class="{ active: active === 'transferHistory' }"
                class="white_button li_button"
                @click="active = 'transferHistory'"
                data-testid="transferHistory"
              >
                {{ $t('rebatePaymentHistory.transferHistory') }}
              </li>
            </ul>
          </div>

          <div class="select_table_box">
            <ul>
              <li :class="{ active: active === 'rebateHistory' }">
                <div class="table_box">
                  <el-table :data="rebateHistoryData.rows" style="width: 100%">
                    <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120"></el-table-column>

                    <el-table-column
                      prop="rangeDate"
                      :label="$t('common.keys.DATERANGE')"
                      min-width="200"
                    ></el-table-column>

                    <el-table-column prop="amount" :label="$t('common.keys.amount')" min-width="120">
                      <template slot-scope="rebateTable">
                        {{ currAccountCurrency | currencySymbol }}{{ rebateTable.row.amount | currency('') }}
                      </template>
                    </el-table-column>

                    <el-table-column prop="status" :label="$t('common.keys.STATUS')" min-width="120">
                      <template slot-scope="scope">
                        <div
                          :class="
                            scope.row.status === 4
                              ? 'red'
                              : scope.row.status === 0 || scope.row.status === 5
                              ? 'gray'
                              : 'gray'
                          "
                        >
                          {{ $t(rebateStatusDesc(scope.row.status)) }}
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="page_box clearfix">
                  <el-pagination
                    :background="true"
                    :page-size="25"
                    layout="prev, pager, next"
                    :total="rebateHistoryData.total"
                    @current-change="handleRebateCurrentChange"
                    @size-change="handleRebateSizeChange"
                    :current-page.sync="rebatePageNo"
                  ></el-pagination>
                </div>
              </li>

              <li :class="{ active: active === 'withdrawHistory' }">
                <div class="table_box">
                  <el-table :data="withdrawHistoryData.rows" style="width: 100%">
                    <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120"></el-table-column>

                    <el-table-column prop="amount" :label="$t('common.keys.amount')" min-width="120">
                      <template slot-scope="withdrawTable">
                        {{ currAccountCurrency | currencySymbol }}{{ withdrawTable.row.amount | currency('') }}
                      </template>
                    </el-table-column>

                    <el-table-column prop="destination" :label="$t('common.keys.DEST')" min-width="120">
                      <template slot-scope="scope">{{ $t(withdrawTypeDesc(scope.row.destination)) }} </template>
                    </el-table-column>

                    <el-table-column prop="status" :label="$t('common.keys.STATUS')" min-width="120">
                      <template slot-scope="scope">
                        <div :class="getWithdrawStatusColor(scope.row.status, scope.row.processedByOp)">
                          {{ $t(withdrawStatusDesc(scope.row.status, scope.row.processedByOp)) }}
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column prop="status" min-width="120">
                      <template slot-scope="scope">
                        <div>
                          <el-button
                            v-if="showCancelButton(scope.row.status, scope.row.processedByOp)"
                            @click="cancelWithdraw(scope.row)"
                            size="mini"
                            round
                            plain
                          >
                            {{ $t('common.button.cancel') }}
                          </el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="page_box clearfix">
                  <el-pagination
                    :background="true"
                    :page-size="25"
                    layout="prev, pager, next"
                    :total="withdrawHistoryData.total"
                    @current-change="handleWithdrawCurrentChange"
                    @size-change="handleWithdrawSizeChange"
                    :current-page.sync="withdrawPageNo"
                  ></el-pagination>
                </div>
              </li>

              <li :class="{ active: active === 'transferHistory' }">
                <div class="table_box">
                  <el-table :data="transferHistoryData.rows" style="width: 100%">
                    <el-table-column :label="$t('common.keys.DATE')">
                      <template slot-scope="scope">{{ scope.row.date | date }}</template>
                    </el-table-column>

                    <el-table-column :label="$t('common.keys.FROM')">
                      <template slot-scope="scope">
                        {{ scope.row.fromAccount + ' (' + scope.row.from_currency + ')' }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('common.keys.TO')">
                      <template slot-scope="scope">
                        {{ scope.row.toAccount + ' (' + scope.row.to_currency + ')' }}
                      </template>
                    </el-table-column>

                    <el-table-column prop="amount" :label="$t('common.keys.amount')">
                      <template slot-scope="transferTable">
                        {{ currAccountCurrency | currencySymbol }}{{ transferTable.row.amount | currency('') }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('common.keys.STATUS')">
                      <template slot-scope="scope">{{ scope.row.status | payStatus }}</template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="page_box clearfix">
                  <el-pagination
                    :background="true"
                    :page-size="25"
                    layout="prev, pager, next"
                    :total="transferHistoryData.total"
                    @current-change="handleTransferCurrentChange"
                    @size-change="handleTransferSizeChange"
                    :current-page.sync="transferPageNo"
                  >
                  </el-pagination>
                </div>
              </li>
            </ul>
          </div>

          <vDialog :show.sync="dialogVisible">
            <div class="cancelWithdrawDialog">
              <h5 v-html="$t('rebatePaymentHistory.cancelWithdraw')"></h5>

              <el-button class="white_button" @click="closeDialog">{{ $t('common.field.no') }}</el-button>

              <el-button class="purple_button" @click="submitCancelWithdraw()">{{ $t('common.field.yes') }}</el-button>
            </div>
          </vDialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rebate from '@/components/Rebate';
import vDialog from '@/components/vDialog';
import DateRangePicker from '@/components/DateRangePicker';
import { apiRebate, apiCancelWithdraw } from '@/resource';

export default {
  components: { Rebate, DateRangePicker, vDialog },
  data() {
    return {
      dialogVisible: false,
      cancelInfo: null,
      userID: this.$store.state.common.CUID,
      startDate: '',
      endDate: '',
      startDateDisplay: '',
      endDateDisplay: '',
      accountID: '',
      currAccountCurrency: '',
      rebatePageNo: 1,
      rebatePageSize: 25,
      withdrawPageNo: 1,
      withdrawPageSize: 25,
      transferPageNo: 1,
      transferPageSize: 25,
      rebateHistoryData: { rows: [], total: 0 },
      withdrawHistoryData: {
        rows: [],
        total: 0
      },
      transferHistoryData: {
        rows: [],
        total: 0
      },
      active: 'rebateHistory'
    };
  },
  methods: {
    cancelWithdraw(info) {
      this.dialogVisible = true;
      this.cancelInfo = info;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.cancelInfo = null;
    },
    showCancelButton(status, processedByOp) {
      return !processedByOp && (status === 1 || status === 3 || status === 5);
    },
    getWithdrawStatus(status, processedByOp) {
      if (status === 9) {
        return 3;
      }

      if (status === 11) {
        return !processedByOp ? 2 : 8;
      }

      return !processedByOp && (status === 3 || status === 5) ? 1 : status;
    },
    submitCancelWithdraw() {
      apiCancelWithdraw({ withdrawalId: this.cancelInfo.id })
        .then(result => {
          if (result.data.code == 0) {
            this.closeDialog();
            this.queryRebateHistory(1);
          } else {
            this.showErrorMsg();
          }
        })
        .catch(err => {
          this.showErrorMsg();
        });
    },
    showErrorMsg() {
      this.loading = false;
      this.$message({
        message: this.$t('rebatePaymentHistory.cancelError'),
        type: 'error',
        duration: 10000
      });
      this.closeDialog();
      this.queryRebateHistory(1);
    },
    setStartAndEndDate() {
      this.startDateDisplay = this.startDate;
      this.endDateDisplay = this.endDate;
      this.$nextTick(() => this.initHistory());
    },
    //分页
    handleRebateCurrentChange(pageNo) {
      this.rebatePageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(0));
    },
    handleRebateSizeChange(size) {
      this.rebatePageSize = size;
      this.$nextTick(() => this.queryRebateHistory(0));
    },
    handleWithdrawCurrentChange(pageNo) {
      this.withdrawPageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(1));
    },
    handleWithdrawSizeChange(size) {
      this.withdrawPageSize = size;
      this.$nextTick(() => this.queryRebateHistory(1));
    },
    handleTransferCurrentChange(pageNo) {
      this.transferPageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(2));
    },
    handleTransferSizeChange(size) {
      this.transferPageSize = size;
      this.$nextTick(() => this.queryRebateHistory(2));
    },
    initHistory() {
      this.queryRebateHistory(0);
      this.queryRebateHistory(1);
      this.queryRebateHistory(2);
    },
    queryRebateHistory(type) {
      if (!this.accountID) {
        return;
      }

      let urls = ['rebateHistory', 'withdrawHistory', 'transferHistory'];
      let pageNo = [this.rebatePageNo, this.withdrawPageNo, this.transferPageNo];
      let pageSize = [this.rebatePageSize, this.withdrawPageSize, this.transferPageSize];
      apiRebate(urls[type], {
        pageNo: pageNo[type],
        limit: pageSize[type],
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startDate,
        qEndTime: this.endDate
      }).then(resp => {
        if (resp.data.code === 0) {
          if (type === 0) {
            this.rebateHistoryData.rows = resp.data.data;
            this.rebateHistoryData.total = resp.data.totalRecords;
          } else if (type === 1) {
            this.withdrawHistoryData.rows = resp.data.data;
            this.withdrawHistoryData.total = resp.data.totalRecords;
          } else {
            this.transferHistoryData.rows = resp.data.data;
            this.transferHistoryData.total = resp.data.totalRecords;
          }
        } else if (resp.data.code === 305) {
          if (type === 0) {
            this.rebateHistoryData.rows = [];
            this.rebateHistoryData.total = 0;
          } else if (type === 1) {
            this.withdrawHistoryData.rows = [];
            this.withdrawHistoryData.total = 0;
          } else {
            this.transferHistoryData.rows = [];
            this.transferHistoryData.total = 0;
          }
        }
      }, this.$handleError);
    },
    getAccountIdAndCurrency(accountID, currency) {
      this.accountID = accountID;
      this.currAccountCurrency = currency;
      this.$nextTick(() => this.initHistory());
    },
    getWithdrawStatusColor(status, processedByOp) {
      switch (true) {
        case [4, 13].includes(status):
          return 'red';
        case [3, 9, 15, 20].includes(this.getWithdrawStatus(status, processedByOp)):
          return 'gray';
        default:
          return 'white';
      }
    }
  },
  mounted() {
    this.bus.$on('updateRebateHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(0);
      }
    });
    this.bus.$on('updateWithdrawHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(1);
      }
    });
    this.bus.$on('updateTransferHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(2);
      }
    });
    this.startDateDisplay = this.startDate;
    this.endDateDisplay = this.endDate;
  },
  computed: {
    showCancelWithdraw() {
      const found =
        this.withdrawHistoryData.rows === null
          ? false
          : this.withdrawHistoryData.rows.find(
              element =>
                element.processedByOp === null && (element.status === 1 || element.status === 3 || element.status === 5)
            );
      return this.$config.getCancelWithdraw(this.regulator) && found;
    },
    rebateStatusDesc() {
      return function (status) {
        const statuses = {
          0: 'rebate.status.processing',
          5: 'rebate.status.processing',
          2: 'rebate.status.processed',
          4: 'rebate.status.rejected'
        };
        return statuses[status];
      };
    },
    withdrawStatusDesc() {
      return function (status, processedByOp) {
        let statusCode = this.getWithdrawStatus(status, processedByOp);

        const statuses = {
          1: 'withdraw.status.withdrawalSubmitted',
          2: 'withdraw.status.withdrawalCanceled',
          3: 'withdraw.status.withdrawalAuditing',
          4: 'withdraw.status.withdrawalRejected',
          5: 'withdraw.status.withdrawalAccepted',
          6: 'withdraw.status.withdrawalPayFailed',
          7: 'withdraw.status.withdrawalSuccess',
          8: 'withdraw.status.withdrawalFailed',
          9: 'withdraw.status.withdrawalPending',
          10: 'withdraw.status.withdrawalPartialFailed',
          // WITHDRAW_SUBMIT(1), // 提交中
          // WITHDRAW_CANCEL(2), // 取消
          // WITHDRAW_AUDIT(3), // 审核中
          // WITHDRAW_REFUSE(4), // 拒绝
          // WITHDRAW_ACCEPT(5), // 受理
          // WITHDRAW_PAYFAIL(6), // 打款失败
          // WITHDRAW_SUCCESS(7), // 出金成功
          // WITHDRAW_FAIL(8), // 出金失败
          // WITHDRAW_PENDING(9), // 没有决定的状态，挂着
          // WITHDRAW_HALFFAILED(10); // 部分失败
          // WITHDRAW_REVERSE_FAILED(20);
          11: 'withdraw.status.transferSubmitted',
          12: 'withdraw.status.transferSuccess',
          13: 'withdraw.status.transferRejected',
          14: 'withdraw.status.transferFailed',
          15: 'withdraw.status.transferProcessing',
          20: 'withdraw.status.transferProcessing'
          //10+status
          // TRANSFER_SUBMITED(1), // 提交
          // TRANSFER_SUCCESS(2), // 转账成功
          // TRANSFER_REJECT(3), // 审核拒绝
          // TRANSFER_FAIL(4), // 转账失败
          // TRANSFER_PROCESSING(5); // mt4入金成功更新数据库失败--处理中
        };
        return statuses[statusCode];
      };
    },
    withdrawTypeDesc() {
      return function (status) {
        const statuses = {
          //country id: 1, Australia; 2, International bank; 3, China
          2: 'common.withdrawChannel.banktransfer',
          3: 'common.withdrawChannel.skrill_neteller',
          4: 'common.withdrawChannel.unionpay',
          5: 'common.withdrawChannel.thailandinstantbankwiretransfer',
          6: 'common.withdrawChannel.malaysiainstantbankwiretransfer',
          7: 'common.withdrawChannel.fasapay',
          8: 'common.withdrawChannel.vietnaminstantbankwiretransfer',
          10: 'common.withdrawChannel.transfer',
          21: 'common.withdrawChannel.aus',
          22: 'common.withdrawChannel.int',
          24: 'common.withdrawChannel.indiainstantbanktransfer',
          23: 'common.withdrawChannel.chn',
          31: 'common.withdrawChannel.skrill',
          32: 'common.withdrawChannel.neteller',
          34: 'common.withdrawChannel.cryptocurrencybitcoin',
          36: 'common.withdrawChannel.cryptocurrencyusdt',
          39: 'common.withdrawChannel.indonesiainstantbankwiretransfer',
          40: 'common.withdrawChannel.philippinesinstantbankwiretransfer',
          47: 'common.withdrawChannel.laosinstantbankwiretransfer',
          48: 'common.withdrawChannel.dasshpeupi',
          49: 'common.withdrawChannel.hongkongbanktransfer',
          61: 'common.withdrawChannel.perfectmoney',
          63: 'common.withdrawChannel.internetbankingmexico',
          64: 'common.withdrawChannel.internetbankingbrazil'
        };
        return statuses[status];
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/rebatePaymentHistory.scss';
</style>
